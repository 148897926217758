.toogle-button {
    display: flex;
    flex-flow: column;
    align-items: center;
    color: var(--toogle-text-light);
    padding: 10px;
    background: var(--toogle-background);
    flex-direction: row;
}

.dark .toogle-button {
    color: var(--toogle-text-dark);
    background: var(--toogle-backgroun-dark);
}

.toogle-button > span {
    margin-bottom: 5px;
    display: inline-block;
}

.toogle-button label {
    border: 1px solid rgb(204 204 204 / .3);
    width: 35px;
    display: block;
    height: 15px;
    background: rgb(355 355 355 / .5);
    border-radius: 15px;
    position: relative;
}

.toogle-button label input {
    height: 0;
    width: 0;
    overflow: hidden;
}

.toogle-button label span {
    content: ' ';
    width: 20px;
    height: 20px;
    display: block;
    position: absolute;
    background: var(--toogle-desactive-color);
    line-height: 20px;
    border-radius: 20px;
    top: -3px;
    left: -3px;
    border: 1px solid #ccc;
    transition: all linear .3s;
}

.toogle-button label input:checked + span {
    left: 16px;
    transition: all linear .3s;
    background: var(--toogle-active-color);
}