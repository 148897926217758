.timer {
    align-items: center;
    padding: 10px;
    background: var(--toogle-background);
    text-align: center;
    color: var(--toogle-text-light);
}

.dark .timer {
    background: var(--toogle-backgroun-dark);
    color: var(--toogle-text-dark);
}