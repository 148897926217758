.sudoku-board .row .column input {
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: var(--cell-border-light);
    background: var(--cell-bg-light);
    transition: background linear .4s, border-color linear .4s;
}

.dark .sudoku-board .row .column input {
    border-color: var(--cell-border-dark);
    background: var(--cell-bg-dark);
    transition: background linear .4s, border-color linear .4s;
}

.sudoku-board .row.error .column input,
.sudoku-board .row .error.column input {
    background: var(--error-bg-light);
    transition: background linear .4s, border-color linear .4s;
}

.dark .sudoku-board .row.error .column input,
.dark .sudoku-board .row .error.column input {
    background: var(--error-bg-dark);
    transition: background linear .4s, border-color linear .4s;
}

.sudoku-board .row .column.section-last input {
    border-right-color: var(--section-border-light);
    border-right-width: 1.4px;
    transition: background linear .4s, border-color linear .4s;
}

.sudoku-board .row.row-section-last .column input {
    border-bottom-color: var(--section-border-light);
    transition: background linear .4s, border-color linear .4s;
    border-bottom-width: 1.4px;
}

.dark .sudoku-board .row .column.section-last input {
    border-right-color: var(--section-border-dark);
    transition: background linear .4s, border-color linear .4s;
}

.dark .sudoku-board .row.row-section-last .column input {
    border-bottom-color: var(--section-border-dark);
    transition: background linear .4s, border-color linear .4s;
}

.sudoku-board .row .column:last-child input {
    border-right-width: 0;        
}

.sudoku-board .row:last-child .column input {
    border-bottom-width: 0;        
}

.sudoku-board .row .column input {
    width: 100%;
    font-size: var(--xl-size);
    text-align: center;
    line-height: 3em;
    padding: 0;
    text-align: center;
    z-index: 2;
    box-sizing: border-box;
}

.sudoku-board .row .column input:focus {
    outline: none;
}

.sudoku-board .row .column .possible {
    z-index: 1;
    position: absolute;
    color: var(--text-light-gray);
    font-size: var(--s-size);
    line-height: 1.5em;
    opacity: .1;
    display: none;
}

.show-tips .sudoku-board .row .column .possible {
    display: inherit;
}

.sudoku-board .row .column:hover .possible {
    opacity: 1;
}

.sudoku-board .row .column .possible:nth-child(1),
.sudoku-board .row .column .possible:nth-child(2),
.sudoku-board .row .column .possible:nth-child(3) {
    left: 5px;
    right: inherit;
}

.sudoku-board .row .column .possible:nth-child(4),
.sudoku-board .row .column .possible:nth-child(5),
.sudoku-board .row .column .possible:nth-child(6) {
    left: 44%;
    right: inherit;
}

.sudoku-board .row .column .possible:nth-child(7),
.sudoku-board .row .column .possible:nth-child(8),
.sudoku-board .row .column .possible:nth-child(9) {
    right: 5px;
}

.sudoku-board .row .column .possible:nth-last-child(1),
.sudoku-board .row .column .possible:nth-last-child(2),
.sudoku-board .row .column .possible:nth-last-child(3) {
    right: 5px;
    left: inherit;
}

.sudoku-board .row .column .possible:nth-child(1),
.sudoku-board .row .column .possible:nth-child(4),
.sudoku-board .row .column .possible:nth-child(7) {
    top: 2px;
}

.sudoku-board .row .column .possible:nth-child(2),
.sudoku-board .row .column .possible:nth-child(5),
.sudoku-board .row .column .possible:nth-child(8) {
    top: calc(50% - 8px);
}

.sudoku-board .row .column .possible:nth-child(3),
.sudoku-board .row .column .possible:nth-child(6),
.sudoku-board .row .column .possible:nth-child(9),
.sudoku-board .row .column .possible:nth-child(5):nth-last-child(4) {
    bottom: 2px;
    top: inherit;
}

input.field::-webkit-outer-spin-button,
input.field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.field[type=number] {
  -moz-appearance: textfield;
}

.row {
    counter-reset: col_n;
}

.column {
    counter-increment: col_n;
    position: relative;
}

.row:first-child .column {
    margin-top: 30px;
}

.column:before {
    content: counter(col_n, lower-alpha) " ";
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 0 0 0;
    color: var(--text-dark-gray);
    width: 100%;
    position: absolute;
    top: -26px;
}

.dark .column:before {
    color: var(--text-light-gray);
}

.row:first-child .column:before {
    display: flex;
}